import React, { Component } from 'react'
import Layout from '../components/Layout'

export default class AgentPrivacyPolicy extends Component {
    render() {
        return (
            <Layout
                title="Agent Privacy Policy | HomeSpotter"
                description="This statement applies to personal information collected via a click-through webform and provided to a real estate agent according to the consent given within the Form workflow."
            >
                <div className="agent privacy-policy">
                    <h1>Privacy Policy Regarding Click-through Web Forms</h1>
                    <p>
                        This statement applies to personal information collected
                        via a click-through web form and provided to a real
                        estate agent according to the consent given within the
                        Form workflow.
                    </p>
                    <p>
                        The collector of the information is the real estate
                        agent identified in the Form (the “Agent”). The
                        information that may be collected via the Form is: Name,
                        Physical Address, Email Address, and Telephone number
                        (the “Personal Information”).
                    </p>
                    <p>
                        The potential uses of this Personal Information are as
                        follows:
                        <br /> · The Personal Information may be used to
                        communicate with you about the range of services
                        provided by the Agent, to determine your potential need
                        for the Agent’s services.
                        <br /> · The Personal Information may also be used to
                        provide you with certain benefits, such as an estimate
                        of the potential selling price for your home.
                        <br /> · The Agent may use the Personal Information for
                        other purposes, which will be specified in the Agent’s
                        privacy policy.
                    </p>
                    <p>
                        The Agent may share the information with and employ
                        third parties to perform some or all of these actions.
                        All uses of the Personal Information by such third
                        parties shall be deemed to be on behalf of the Agent.
                    </p>
                    <p>
                        The Agent shall ensure that all uses of the Personal
                        Information (including those performed by third parties)
                        will comply with this statement and with this policy
                        and/or the Agent’s privacy policy.
                    </p>
                </div>
            </Layout>
        )
    }
}
